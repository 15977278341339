/*===============================
=            Lexend            =
===============================*/

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lexend';
    src: url('../fonts/lexend/Lexend-Bold.ttf');
    font-weight: 600;
    font-style: normal;
}


/*=====  End of Lexend  ======*/

/*===============================
=            Inter            =
===============================*/

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Bold.ttf');
    font-weight: 600;
    font-style: normal;
}


/*=====  End of Lexend  ======*/


/*===============================
=            Halvetica neue            =
===============================*/

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/halvetica_neue/HelveticaNeue-Roman.eot');
    src: url('../fonts/halvetica_neue/HelveticaNeue-Roman.eot?#iefix') format('embedded-opentype'), url('../fonts/halvetica_neue/HelveticaNeue-Roman.woff2') format('woff2'), url('../fonts/halvetica_neue/HelveticaNeue-Roman.woff') format('woff'), url('../fonts/halvetica_neue/HelveticaNeue-Roman.ttf') format('truetype'), url('../fonts/halvetica_neue/HelveticaNeue-Roman.svg#HelveticaNeue-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*=====  End of Halvetica neue  ======*/