@charset "UTF-8";

@mixin media($query: $feature $value $columns, $total-columns: $grid-columns) {
  @if length($query) == 1 {
    @media screen and ($default-feature: nth($query, 1)) {
      $default-grid-columns: $grid-columns;
      $grid-columns: $total-columns !global;
      @content;
      $grid-columns: $default-grid-columns !global;
    }
  } @else {
    $loop-to: length($query);
    $media-query: "screen and ";
    $default-grid-columns: $grid-columns;
    $grid-columns: $total-columns !global;

    @if is-not(is-even(length($query))) {
      $grid-columns: nth($query, $loop-to) !global;
      $loop-to: $loop-to - 1;
    }

    $i: 1;
    @while $i <= $loop-to {
      $media-query: $media-query + "(" + nth($query, $i) + ": " + nth($query, $i + 1) + ") ";

      @if ($i + 1) != $loop-to {
        $media-query: $media-query + "and ";
      }

      $i: $i + 2;
    }

    @media #{$media-query} {
      @content;
      $grid-columns: $default-grid-columns !global;
    }
  }
}
