@charset "UTF-8";

@mixin outer-container($local-max-width: $max-width) {
  @include clearfix;
  max-width: $local-max-width;
  margin: {
    left: auto;
    right: auto;
  }
}
